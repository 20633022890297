@import '../components/Loading/Loading.scss';
body {
  margin: 0;
}
.active {
  display: block;
}

.hidden {
  display: none;
}